import { graphql } from "gatsby";
import React ,{useState,useEffect,useCallback} from "react";
import ConditionallyRender from "../components/conditionallyRender";
import ContentContainer from "../components/contentContainer";
import Layout from '../components/layout'
import Slider from "../components/slider";
import ScrollingTextBanner from "../components/scrollingTextBanner";
import LineDivider from "../components/LineDivider";
import Header from "../components/header";
import SEO from "../components/seo";
import Spacer from "../components/spacer";
import NextProject from '../components/nextPage'
import { kebabCase } from 'lodash'


function Video({ url }) {
    const [mediaSource, setMediaSource] = useState(null)
    const [playing, setPlaying] = useState(false)
    const media = useCallback((node) => {
        setMediaSource(node)
    },[])

    useEffect(() => {
        if (mediaSource) {
            if (playing) {
                mediaSource.play()
            } else {
                mediaSource.pause()
            }
        }
    }, [playing, mediaSource])

    return (
        <ConditionallyRender when={url}>
            <div className="max-1200 ma ">
            <div class="ratio-16-9 pos-rel">
                <button onClick={() => setPlaying((state) => !state)} class="bg-image video bg-image z-2  ">
                    <video onPause={() => setPlaying(false)} ref={media} playsinline={true}  class="bg-image cover" controls={false} l  >
                        <source src={url} type="video/mp4" />
                        <track kind="captions" src="" />Your browser does not support HTML5 video.
                    </video>
                    <div class={"bg-image  video " + (playing ? 'playing' : 'paused')}>
                    </div>
                </button>
            </div>
            </div>
        </ConditionallyRender>
    )
}

const SmallDevelopments = ({ data :{project:{title,date:year,location,about,featureImage,sliderImages,videoUrl,nextProject,secondVideoUrl}} }) => {
    const {title:nextTitle,featureImage:nextImage} =   nextProject 


    return (
        <Layout>
            <SEO title={title} />
            <Header page='Project' />
            <Spacer className='m-show m-h70'/>
            <ScrollingTextBanner title={title} image={featureImage?.gatsbyImageData}  caption={about} >
                <p className='m0 uppercase mt10 op-50 '>{year} - {location}</p>
            </ScrollingTextBanner>
            <LineDivider first={false} />

            <ConditionallyRender when={videoUrl}>
                <ContentContainer className="pt0 pb0">
                    <Video url={videoUrl}/> 
                </ContentContainer>
            </ConditionallyRender>
            <Spacer/>
            <ConditionallyRender when={secondVideoUrl}>
                <ContentContainer className="pt0 pb0">
                    <Video url={secondVideoUrl}/> 
                </ContentContainer>
            </ConditionallyRender>
            <Spacer/>

            <ConditionallyRender when={(sliderImages.length > 0)}>
                <ContentContainer className="pt0 pb0">

                    <Slider images={sliderImages.map(({url}) => url)} />
                </ContentContainer>
            </ConditionallyRender>
            <Spacer/>
            <NextProject title={nextTitle} image={nextImage} link={`/projects/${kebabCase(nextTitle?.toLowerCase())}`} />
        </Layout>
    )
}
export default SmallDevelopments

export const query = graphql`
    query SmallDevelopments($id:String){
        project: datoCmsProject(id: {eq: $id}) {
            featureProject
            date
            title
            location
            info
            about
            featureImage {
                gatsbyImageData
            }
            sliderImages {
                url
            }  
            secondVideoUrl
            videoUrl 
            nextProject{
                featureImage {
                    gatsbyImageData
                }
                title
            }
        }
    }
`